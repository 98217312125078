@charset "UTF-8";
:root,
:host {
  --font-family-emoji: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-sans-serif: system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, Helvetica, Arial, "Helvetica Neue", sans-serif, var(--font-family-emoji);
  --font-family-monospace: ui-monospace, SFMono-Regular, "SF Mono", Menlo, Consolas, "Liberation Mono", monospace, var(--font-family-emoji);
  --font-family: var(--font-family-sans-serif);
  --line-height: 1.5;
  --font-weight: 400;
  --font-size: 100%;
  --text-underline-offset: 0.1rem;
  --border-radius: 0.25rem;
  --border-width: 0.0625rem;
  --outline-width: 0.125rem;
  --transition: 0.2s ease-in-out;
  --spacing: 1rem;
  --typography-spacing-vertical: 1rem;
  --block-spacing-vertical: var(--spacing);
  --block-spacing-horizontal: var(--spacing);
  --form-element-spacing-vertical: 0.75rem;
  --form-element-spacing-horizontal: 1rem;
  --group-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  --group-box-shadow-focus-with-button: 0 0 0 var(--outline-width) var(--primary-focus);
  --group-box-shadow-focus-with-input: 0 0 0 0.0625rem var(--form-element-border-color);
  --modal-overlay-backdrop-filter: blur(0.375rem);
  --nav-element-spacing-vertical: 1rem;
  --nav-element-spacing-horizontal: 0.5rem;
  --nav-link-spacing-vertical: 0.5rem;
  --nav-link-spacing-horizontal: 0.5rem;
  --nav-breadcrumb-divider: ">";
  --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  --icon-loading: url("data:image/svg+xml,%3Csvg fill='none' height='24' width='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cstyle%3E g %7B animation: rotate 2s linear infinite; transform-origin: center center; %7D circle %7B stroke-dasharray: 75,100; stroke-dashoffset: -5; animation: dash 1.5s ease-in-out infinite; stroke-linecap: round; %7D @keyframes rotate %7B 0%25 %7B transform: rotate(0deg); %7D 100%25 %7B transform: rotate(360deg); %7D %7D @keyframes dash %7B 0%25 %7B stroke-dasharray: 1,100; stroke-dashoffset: 0; %7D 50%25 %7B stroke-dasharray: 44.5,100; stroke-dashoffset: -17.5; %7D 100%25 %7B stroke-dasharray: 44.5,100; stroke-dashoffset: -62; %7D %7D %3C/style%3E%3Cg%3E%3Ccircle cx='12' cy='12' r='10' fill='none' stroke='rgb(136, 145, 164)' stroke-width='4' /%3E%3C/g%3E%3C/svg%3E");
}
@media (min-width: 576px) {
  :root,
  :host {
    --font-size: 106.25%;
  }
}
@media (min-width: 768px) {
  :root,
  :host {
    --font-size: 112.5%;
  }
}
@media (min-width: 1024px) {
  :root,
  :host {
    --font-size: 118.75%;
  }
}
@media (min-width: 1280px) {
  :root,
  :host {
    --font-size: 125%;
  }
}
@media (min-width: 1536px) {
  :root,
  :host {
    --font-size: 131.25%;
  }
}

a {
  --text-decoration: underline;
}

small {
  --font-size: 0.875em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  --font-weight: 700;
}

h1 {
  --font-size: 2rem;
  --line-height: 1.125;
  --typography-spacing-top: 3rem;
}

h2 {
  --font-size: 1.75rem;
  --line-height: 1.15;
  --typography-spacing-top: 2.625rem;
}

h3 {
  --font-size: 1.5rem;
  --line-height: 1.175;
  --typography-spacing-top: 2.25rem;
}

h4 {
  --font-size: 1.25rem;
  --line-height: 1.2;
  --typography-spacing-top: 1.874rem;
}

h5 {
  --font-size: 1.125rem;
  --line-height: 1.225;
  --typography-spacing-top: 1.6875rem;
}

h6 {
  --font-size: 1rem;
  --line-height: 1.25;
  --typography-spacing-top: 1.5rem;
}

thead th,
thead td,
tfoot th,
tfoot td {
  --font-weight: 600;
  --border-width: 0.1875rem;
}

pre,
code,
kbd,
samp {
  --font-family: var(--font-family-monospace);
}

kbd {
  --font-weight: bolder;
}

input:not([type=submit],
[type=button],
[type=reset],
[type=checkbox],
[type=radio],
[type=file]),
:where(select, textarea) {
  --outline-width: 0.0625rem;
}

[type=search] {
  --border-radius: 5rem;
}

[type=checkbox],
[type=radio] {
  --border-width: 0.125rem;
}

[type=checkbox][role=switch] {
  --border-width: 0.1875rem;
}

[role=search] {
  --border-radius: 5rem;
}

[role=search] button,
[role=search] [type=submit],
[role=search] [type=button],
[role=search] [role=button],
[role=group] button,
[role=group] [type=submit],
[role=group] [type=button],
[role=group] [role=button] {
  --form-element-spacing-horizontal: 2rem;
}

details summary[role=button]::after {
  filter: brightness(0) invert(1);
}

[aria-busy=true]:not(input, select, textarea):is(button, [type=submit], [type=button], [type=reset], [role=button])::before {
  filter: brightness(0) invert(1);
}

progress,
[type=checkbox],
[type=radio],
[type=range] {
  accent-color: var(--primary);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root),
:where(:host) {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: var(--background-color);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: var(--font-family);
  text-underline-offset: var(--text-underline-offset);
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

body {
  width: 100%;
  margin: 0;
}

main {
  display: block;
}

body > header,
body > main,
body > footer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
}
@media (min-width: 576px) {
  body > header,
  body > main,
  body > footer {
    max-width: 510px;
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  body > header,
  body > main,
  body > footer {
    max-width: 700px;
  }
}
@media (min-width: 1024px) {
  body > header,
  body > main,
  body > footer {
    max-width: 950px;
  }
}
@media (min-width: 1280px) {
  body > header,
  body > main,
  body > footer {
    max-width: 1200px;
  }
}
@media (min-width: 1536px) {
  body > header,
  body > main,
  body > footer {
    max-width: 1450px;
  }
}

section {
  margin-bottom: var(--block-spacing-vertical);
}

b,
strong {
  font-weight: bolder;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

address,
blockquote,
dl,
ol,
p,
pre,
table,
ul {
  margin-top: 0;
  margin-bottom: var(--typography-spacing-vertical);
  color: var(--color);
  font-style: normal;
  font-weight: var(--font-weight);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: var(--typography-spacing-vertical);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: var(--font-family);
}

h1 {
  --color: var(--h1-color);
}

h2 {
  --color: var(--h2-color);
}

h3 {
  --color: var(--h3-color);
}

h4 {
  --color: var(--h4-color);
}

h5 {
  --color: var(--h5-color);
}

h6 {
  --color: var(--h6-color);
}

:where(article, address, blockquote, dl, figure, form, ol, p, pre, table, ul) ~ :is(h1, h2, h3, h4, h5, h6) {
  margin-top: var(--typography-spacing-top);
}

p {
  margin-bottom: var(--typography-spacing-vertical);
}

hgroup {
  margin-bottom: var(--typography-spacing-vertical);
}
hgroup > * {
  margin-top: 0;
  margin-bottom: 0;
}
hgroup > *:not(:first-child):last-child {
  --color: var(--muted-color);
  --font-weight: unset;
  font-size: 1rem;
}

:where(ol, ul) li {
  margin-bottom: calc(var(--typography-spacing-vertical) * 0.25);
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
  margin-top: calc(var(--typography-spacing-vertical) * 0.25);
}

ul li {
  list-style: square;
}

mark {
  padding: 0.125rem 0.25rem;
  background-color: var(--mark-background-color);
  color: var(--mark-color);
  vertical-align: baseline;
}

blockquote {
  display: block;
  margin: var(--typography-spacing-vertical) 0;
  padding: var(--spacing);
  border-right: none;
  border-left: 0.25rem solid var(--blockquote-border-color);
  border-inline-start: 0.25rem solid var(--blockquote-border-color);
  border-inline-end: none;
}
blockquote footer {
  margin-top: calc(var(--typography-spacing-vertical) * 0.5);
  color: var(--blockquote-footer-color);
}

abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help;
}

ins {
  color: var(--ins-color);
  text-decoration: none;
}

del {
  color: var(--del-color);
}

::-moz-selection {
  background-color: var(--text-selection-color);
}

::selection {
  background-color: var(--text-selection-color);
}

:where(a:not([role=button])),
[role=link] {
  --color: var(--primary);
  --background-color: transparent;
  --underline: var(--primary-underline);
  outline: none;
  background-color: var(--background-color);
  color: var(--color);
  -webkit-text-decoration: var(--text-decoration);
  text-decoration: var(--text-decoration);
  text-decoration-color: var(--underline);
  text-underline-offset: 0.125em;
  transition: background-color var(--transition), color var(--transition), box-shadow var(--transition), -webkit-text-decoration var(--transition);
  transition: background-color var(--transition), color var(--transition), text-decoration var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), color var(--transition), text-decoration var(--transition), box-shadow var(--transition), -webkit-text-decoration var(--transition);
}
:where(a:not([role=button])):is([aria-current]:not([aria-current=false]), :hover, :active, :focus),
[role=link]:is([aria-current]:not([aria-current=false]), :hover, :active, :focus) {
  --color: var(--primary-hover);
  --underline: var(--primary-hover-underline);
  --text-decoration: underline;
}
:where(a:not([role=button])):focus-visible,
[role=link]:focus-visible {
  box-shadow: 0 0 0 var(--outline-width) var(--primary-focus);
}

a[role=button] {
  display: inline-block;
}

button {
  margin: 0;
  overflow: visible;
  font-family: inherit;
  text-transform: none;
}

button,
[type=submit],
[type=reset],
[type=button] {
  -webkit-appearance: button;
}

button,
[type=submit],
[type=reset],
[type=button],
[type=file]::file-selector-button,
[role=button] {
  --background-color: var(--primary-background);
  --border-color: var(--primary-border);
  --color: var(--primary-inverse);
  --box-shadow: var(--button-box-shadow, 0 0 0 rgba(0, 0, 0, 0));
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  outline: none;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
}
button:is([aria-current]:not([aria-current=false])), button:is(:hover, :active, :focus),
[type=submit]:is([aria-current]:not([aria-current=false])),
[type=submit]:is(:hover, :active, :focus),
[type=reset]:is([aria-current]:not([aria-current=false])),
[type=reset]:is(:hover, :active, :focus),
[type=button]:is([aria-current]:not([aria-current=false])),
[type=button]:is(:hover, :active, :focus),
[type=file]::file-selector-button:is([aria-current]:not([aria-current=false])),
[type=file]::file-selector-button:is(:hover, :active, :focus),
[role=button]:is([aria-current]:not([aria-current=false])),
[role=button]:is(:hover, :active, :focus) {
  --background-color: var(--primary-hover-background);
  --border-color: var(--primary-hover-border);
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 rgba(0, 0, 0, 0));
  --color: var(--primary-inverse);
}
button:focus, button:is([aria-current]:not([aria-current=false])):focus,
[type=submit]:focus,
[type=submit]:is([aria-current]:not([aria-current=false])):focus,
[type=reset]:focus,
[type=reset]:is([aria-current]:not([aria-current=false])):focus,
[type=button]:focus,
[type=button]:is([aria-current]:not([aria-current=false])):focus,
[type=file]::file-selector-button:focus,
[type=file]::file-selector-button:is([aria-current]:not([aria-current=false])):focus,
[role=button]:focus,
[role=button]:is([aria-current]:not([aria-current=false])):focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 rgba(0, 0, 0, 0)), 0 0 0 var(--outline-width) var(--primary-focus);
}

[type=submit],
[type=reset],
[type=button] {
  margin-bottom: var(--spacing);
}

[type=reset],
[type=file]::file-selector-button {
  --background-color: var(--secondary-background);
  --border-color: var(--secondary-border);
  --color: var(--secondary-inverse);
  cursor: pointer;
}
[type=reset]:is([aria-current]:not([aria-current=false]), :hover, :active, :focus),
[type=file]::file-selector-button:is([aria-current]:not([aria-current=false]), :hover, :active, :focus) {
  --background-color: var(--secondary-hover-background);
  --border-color: var(--secondary-hover-border);
  --color: var(--secondary-inverse);
}
[type=reset]:focus,
[type=file]::file-selector-button:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 rgba(0, 0, 0, 0)), 0 0 0 var(--outline-width) var(--secondary-focus);
}

:where(button, [type=submit], [type=reset], [type=button], [role=button])[disabled],
:where(fieldset[disabled]) :is(button, [type=submit], [type=button], [type=reset], [role=button]) {
  opacity: 0.5;
  pointer-events: none;
}

:where(table) {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

th,
td {
  padding: calc(var(--spacing) / 2) var(--spacing);
  border-bottom: var(--border-width) solid var(--table-border-color);
  background-color: var(--background-color);
  color: var(--color);
  font-weight: var(--font-weight);
  text-align: left;
  text-align: start;
}

tfoot th,
tfoot td {
  border-top: var(--border-width) solid var(--table-border-color);
  border-bottom: 0;
}

table.striped tbody tr:nth-child(odd) th,
table.striped tbody tr:nth-child(odd) td {
  background-color: var(--table-row-stripped-background-color);
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

:where(iframe) {
  border-style: none;
}

img {
  max-width: 100%;
  height: auto;
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

svg:not(:root),
svg:not(:host) {
  overflow: hidden;
}

pre,
code,
kbd,
samp {
  font-size: 0.875em;
  font-family: var(--font-family);
}

pre code,
pre samp {
  font-size: inherit;
  font-family: inherit;
}

pre {
  -ms-overflow-style: scrollbar;
  overflow: auto;
}

pre,
code,
kbd,
samp {
  border-radius: var(--border-radius);
  background: var(--code-background-color);
  color: var(--code-color);
  font-weight: var(--font-weight);
  line-height: initial;
}

code,
kbd,
samp {
  display: inline-block;
  padding: 0.375rem;
}

pre {
  display: block;
  margin-bottom: var(--spacing);
  overflow-x: auto;
}
pre > code,
pre > samp {
  display: block;
  padding: var(--spacing);
  background: none;
  line-height: var(--line-height);
}

kbd {
  background-color: var(--code-kbd-background-color);
  color: var(--code-kbd-color);
  vertical-align: baseline;
}

figure {
  display: block;
  margin: 0;
  padding: 0;
}
figure figcaption {
  padding: calc(var(--spacing) * 0.5) 0;
  color: var(--muted-color);
}

hr {
  height: 0;
  margin: var(--typography-spacing-vertical) 0;
  border: 0;
  border-top: 1px solid var(--muted-border-color);
  color: inherit;
}

[hidden],
template {
  display: none !important;
}

canvas {
  display: inline-block;
}

input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: 1rem;
  line-height: var(--line-height);
  font-family: inherit;
  letter-spacing: inherit;
}

input {
  overflow: visible;
}

select {
  text-transform: none;
}

legend {
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

:-moz-focusring {
  outline: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-ms-expand {
  display: none;
}

[type=file],
[type=range] {
  padding: 0;
  border-width: 0;
}

input:not([type=checkbox], [type=radio], [type=range]) {
  height: calc(1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
}

fieldset {
  width: 100%;
  margin: 0;
  margin-bottom: var(--spacing);
  padding: 0;
  border: 0;
}

label,
fieldset legend {
  display: block;
  margin-bottom: calc(var(--spacing) * 0.375);
  color: var(--color);
  font-weight: var(--form-label-font-weight, var(--font-weight));
}

fieldset legend {
  margin-bottom: calc(var(--spacing) * 0.5);
}

input:not([type=checkbox], [type=radio]),
button[type=submit],
select,
textarea {
  width: 100%;
}

input:not([type=checkbox], [type=radio], [type=range], [type=file]),
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
}

input,
select,
textarea {
  --background-color: var(--form-element-background-color);
  --border-color: var(--form-element-border-color);
  --color: var(--form-element-color);
  --box-shadow: none;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  outline: none;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
}

input:not([type=submit],
[type=button],
[type=reset],
[type=checkbox],
[type=radio],
[readonly]):is(:active, :focus),
:where(select, textarea):not([readonly]):is(:active, :focus) {
  --background-color: var(--form-element-active-background-color);
}

input:not([type=submit], [type=button], [type=reset], [role=switch], [readonly]):is(:active, :focus),
:where(select, textarea):not([readonly]):is(:active, :focus) {
  --border-color: var(--form-element-active-border-color);
}

input:not([type=submit],
[type=button],
[type=reset],
[type=range],
[type=file],
[readonly]):focus,
:where(select, textarea):not([readonly]):focus {
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

input:not([type=submit], [type=button], [type=reset])[disabled],
select[disabled],
textarea[disabled],
label[aria-disabled=true],
:where(fieldset[disabled]) :is(input:not([type=submit], [type=button], [type=reset]), select, textarea) {
  opacity: var(--form-element-disabled-opacity);
  pointer-events: none;
}

label[aria-disabled=true] input[disabled] {
  opacity: 1;
}

:where(input, select, textarea):not([type=checkbox],
[type=radio],
[type=date],
[type=datetime-local],
[type=month],
[type=time],
[type=week],
[type=range])[aria-invalid] {
  padding-right: calc(var(--form-element-spacing-horizontal) + 1.5rem) !important;
  padding-left: var(--form-element-spacing-horizontal);
  padding-inline-start: var(--form-element-spacing-horizontal) !important;
  padding-inline-end: calc(var(--form-element-spacing-horizontal) + 1.5rem) !important;
  background-position: center right 0.75rem;
  background-size: 1rem auto;
  background-repeat: no-repeat;
}
:where(input, select, textarea):not([type=checkbox],
[type=radio],
[type=date],
[type=datetime-local],
[type=month],
[type=time],
[type=week],
[type=range])[aria-invalid=false]:not(select) {
  background-image: var(--icon-valid);
}
:where(input, select, textarea):not([type=checkbox],
[type=radio],
[type=date],
[type=datetime-local],
[type=month],
[type=time],
[type=week],
[type=range])[aria-invalid=true]:not(select) {
  background-image: var(--icon-invalid);
}
:where(input, select, textarea)[aria-invalid=false] {
  --border-color: var(--form-element-valid-border-color);
}
:where(input, select, textarea)[aria-invalid=false]:is(:active, :focus) {
  --border-color: var(--form-element-valid-active-border-color) !important;
}
:where(input, select, textarea)[aria-invalid=false]:is(:active, :focus):not([type=checkbox], [type=radio]) {
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-valid-focus-color) !important;
}
:where(input, select, textarea)[aria-invalid=true] {
  --border-color: var(--form-element-invalid-border-color);
}
:where(input, select, textarea)[aria-invalid=true]:is(:active, :focus) {
  --border-color: var(--form-element-invalid-active-border-color) !important;
}
:where(input, select, textarea)[aria-invalid=true]:is(:active, :focus):not([type=checkbox], [type=radio]) {
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-invalid-focus-color) !important;
}

[dir=rtl] :where(input, select, textarea):not([type=checkbox], [type=radio]):is([aria-invalid], [aria-invalid=true], [aria-invalid=false]) {
  background-position: center left 0.75rem;
}

input::placeholder,
input::-webkit-input-placeholder,
textarea::placeholder,
textarea::-webkit-input-placeholder,
select:invalid {
  color: var(--form-element-placeholder-color);
  opacity: 1;
}

input:not([type=checkbox], [type=radio]),
select,
textarea {
  margin-bottom: var(--spacing);
}

select::-ms-expand {
  border: 0;
  background-color: transparent;
}
select:not([multiple], [size]) {
  padding-right: calc(var(--form-element-spacing-horizontal) + 1.5rem);
  padding-left: var(--form-element-spacing-horizontal);
  padding-inline-start: var(--form-element-spacing-horizontal);
  padding-inline-end: calc(var(--form-element-spacing-horizontal) + 1.5rem);
  background-image: var(--icon-chevron);
  background-position: center right 0.75rem;
  background-size: 1rem auto;
  background-repeat: no-repeat;
}
select[multiple] option:checked {
  background: var(--form-element-selected-background-color);
  color: var(--form-element-color);
}

[dir=rtl] select:not([multiple], [size]) {
  background-position: center left 0.75rem;
}

textarea {
  display: block;
  resize: vertical;
}
textarea[aria-invalid] {
  --icon-height: calc(1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
  background-position: top right 0.75rem !important;
  background-size: 1rem var(--icon-height) !important;
}

:where(input, select, textarea, fieldset) + small {
  display: block;
  width: 100%;
  margin-top: calc(var(--spacing) * -0.75);
  margin-bottom: var(--spacing);
  color: var(--muted-color);
}
:where(input, select, textarea, fieldset)[aria-invalid=false] + small {
  color: var(--ins-color);
}
:where(input, select, textarea, fieldset)[aria-invalid=true] + small {
  color: var(--del-color);
}

label > :where(input, select, textarea) {
  margin-top: calc(var(--spacing) * 0.25);
}

label:has([type=checkbox], [type=radio]) {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

[type=checkbox],
[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  margin-top: -0.125em;
  margin-inline-end: 0.5em;
  border-width: var(--border-width);
  vertical-align: middle;
  cursor: pointer;
}
[type=checkbox]::-ms-check,
[type=radio]::-ms-check {
  display: none;
}
[type=checkbox]:checked, [type=checkbox]:checked:active, [type=checkbox]:checked:focus,
[type=radio]:checked,
[type=radio]:checked:active,
[type=radio]:checked:focus {
  --background-color: var(--primary-background);
  --border-color: var(--primary-border);
  background-image: var(--icon-checkbox);
  background-position: center;
  background-size: 0.75em auto;
  background-repeat: no-repeat;
}
[type=checkbox] ~ label,
[type=radio] ~ label {
  display: inline-block;
  margin-bottom: 0;
  cursor: pointer;
}
[type=checkbox] ~ label:not(:last-of-type),
[type=radio] ~ label:not(:last-of-type) {
  margin-inline-end: 1em;
}

[type=checkbox]:indeterminate {
  --background-color: var(--primary-background);
  --border-color: var(--primary-border);
  background-image: var(--icon-minus);
  background-position: center;
  background-size: 0.75em auto;
  background-repeat: no-repeat;
}

[type=radio] {
  border-radius: 50%;
}
[type=radio]:checked, [type=radio]:checked:active, [type=radio]:checked:focus {
  --background-color: var(--primary-inverse);
  border-width: 0.35em;
  background-image: none;
}

[type=checkbox][role=switch] {
  --background-color: var(--switch-background-color);
  --color: var(--switch-color);
  width: 2.25em;
  height: 1.25em;
  border: var(--border-width) solid var(--border-color);
  border-radius: 1.25em;
  background-color: var(--background-color);
  line-height: 1.25em;
}
[type=checkbox][role=switch]:not([aria-invalid]) {
  --border-color: var(--switch-background-color);
}
[type=checkbox][role=switch]:before {
  display: block;
  aspect-ratio: 1;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color);
  box-shadow: var(--switch-thumb-box-shadow);
  content: "";
  transition: margin 0.1s ease-in-out;
}
[type=checkbox][role=switch]:focus {
  --background-color: var(--switch-background-color);
  --border-color: var(--switch-background-color);
}
[type=checkbox][role=switch]:checked {
  --background-color: var(--switch-checked-background-color);
  --border-color: var(--switch-checked-background-color);
  background-image: none;
}
[type=checkbox][role=switch]:checked::before {
  margin-inline-start: calc(2.25em - 1.25em);
}
[type=checkbox][role=switch][disabled] {
  --background-color: var(--border-color);
}

[type=checkbox][aria-invalid=false]:checked, [type=checkbox][aria-invalid=false]:checked:active, [type=checkbox][aria-invalid=false]:checked:focus,
[type=checkbox][role=switch][aria-invalid=false]:checked,
[type=checkbox][role=switch][aria-invalid=false]:checked:active,
[type=checkbox][role=switch][aria-invalid=false]:checked:focus {
  --background-color: var(--form-element-valid-border-color);
}
[type=checkbox]:checked[aria-invalid=true], [type=checkbox]:checked:active[aria-invalid=true], [type=checkbox]:checked:focus[aria-invalid=true],
[type=checkbox][role=switch]:checked[aria-invalid=true],
[type=checkbox][role=switch]:checked:active[aria-invalid=true],
[type=checkbox][role=switch]:checked:focus[aria-invalid=true] {
  --background-color: var(--form-element-invalid-border-color);
}

[type=checkbox][aria-invalid=false]:checked, [type=checkbox][aria-invalid=false]:checked:active, [type=checkbox][aria-invalid=false]:checked:focus,
[type=radio][aria-invalid=false]:checked,
[type=radio][aria-invalid=false]:checked:active,
[type=radio][aria-invalid=false]:checked:focus,
[type=checkbox][role=switch][aria-invalid=false]:checked,
[type=checkbox][role=switch][aria-invalid=false]:checked:active,
[type=checkbox][role=switch][aria-invalid=false]:checked:focus {
  --border-color: var(--form-element-valid-border-color);
}
[type=checkbox]:checked[aria-invalid=true], [type=checkbox]:checked:active[aria-invalid=true], [type=checkbox]:checked:focus[aria-invalid=true],
[type=radio]:checked[aria-invalid=true],
[type=radio]:checked:active[aria-invalid=true],
[type=radio]:checked:focus[aria-invalid=true],
[type=checkbox][role=switch]:checked[aria-invalid=true],
[type=checkbox][role=switch]:checked:active[aria-invalid=true],
[type=checkbox][role=switch]:checked:focus[aria-invalid=true] {
  --border-color: var(--form-element-invalid-border-color);
}

[type=color]::-webkit-color-swatch-wrapper {
  padding: 0;
}
[type=color]::-moz-focus-inner {
  padding: 0;
}
[type=color]::-webkit-color-swatch {
  border: 0;
  border-radius: calc(var(--border-radius) * 0.5);
}
[type=color]::-moz-color-swatch {
  border: 0;
  border-radius: calc(var(--border-radius) * 0.5);
}

input:not([type=checkbox], [type=radio], [type=range], [type=file]):is([type=date], [type=datetime-local], [type=month], [type=time], [type=week]) {
  --icon-position: 0.75rem;
  --icon-width: 1rem;
  padding-right: calc(var(--icon-width) + var(--icon-position));
  background-image: var(--icon-date);
  background-position: center right var(--icon-position);
  background-size: var(--icon-width) auto;
  background-repeat: no-repeat;
}
input:not([type=checkbox], [type=radio], [type=range], [type=file])[type=time] {
  background-image: var(--icon-time);
}

[type=date]::-webkit-calendar-picker-indicator,
[type=datetime-local]::-webkit-calendar-picker-indicator,
[type=month]::-webkit-calendar-picker-indicator,
[type=time]::-webkit-calendar-picker-indicator,
[type=week]::-webkit-calendar-picker-indicator {
  width: var(--icon-width);
  margin-right: calc(var(--icon-width) * -1);
  margin-left: var(--icon-position);
  opacity: 0;
}

@-moz-document url-prefix() {
  [type=date],
  [type=datetime-local],
  [type=month],
  [type=time],
  [type=week] {
    padding-right: var(--form-element-spacing-horizontal) !important;
    background-image: none !important;
  }
}
[dir=rtl] :is([type=date], [type=datetime-local], [type=month], [type=time], [type=week]) {
  text-align: right;
}

[type=file] {
  --color: var(--muted-color);
  margin-left: calc(var(--outline-width) * -1);
  padding: calc(var(--form-element-spacing-vertical) * 0.5) 0;
  padding-left: var(--outline-width);
  border: 0;
  border-radius: 0;
  background: none;
}
[type=file]::file-selector-button {
  margin-right: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * 0.5) var(--form-element-spacing-horizontal);
}
[type=file]:is(:hover, :active, :focus)::file-selector-button {
  --background-color: var(--secondary-hover-background);
  --border-color: var(--secondary-hover-border);
}
[type=file]:focus::file-selector-button {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 rgba(0, 0, 0, 0)), 0 0 0 var(--outline-width) var(--secondary-focus);
}

[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 1.25rem;
  background: none;
}
[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.375rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -webkit-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}
[type=range]::-moz-range-track {
  width: 100%;
  height: 0.375rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -moz-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}
[type=range]::-ms-track {
  width: 100%;
  height: 0.375rem;
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -ms-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
}
[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.4375rem;
  border: 2px solid var(--range-thumb-border-color);
  border-radius: 50%;
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -webkit-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
}
[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.4375rem;
  border: 2px solid var(--range-thumb-border-color);
  border-radius: 50%;
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -moz-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
}
[type=range]::-ms-thumb {
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.4375rem;
  border: 2px solid var(--range-thumb-border-color);
  border-radius: 50%;
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -ms-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
}
[type=range]:active, [type=range]:focus-within {
  --range-border-color: var(--range-active-border-color);
  --range-thumb-color: var(--range-thumb-active-color);
}
[type=range]:active::-webkit-slider-thumb {
  transform: scale(1.25);
}
[type=range]:active::-moz-range-thumb {
  transform: scale(1.25);
}
[type=range]:active::-ms-thumb {
  transform: scale(1.25);
}

input:not([type=checkbox], [type=radio], [type=range], [type=file])[type=search] {
  padding-inline-start: calc(var(--form-element-spacing-horizontal) + 1.75rem);
  background-image: var(--icon-search);
  background-position: center left calc(var(--form-element-spacing-horizontal) + 0.125rem);
  background-size: 1rem auto;
  background-repeat: no-repeat;
}
input:not([type=checkbox], [type=radio], [type=range], [type=file])[type=search][aria-invalid] {
  padding-inline-start: calc(var(--form-element-spacing-horizontal) + 1.75rem) !important;
  background-position: center left 1.125rem, center right 0.75rem;
}
input:not([type=checkbox], [type=radio], [type=range], [type=file])[type=search][aria-invalid=false] {
  background-image: var(--icon-search), var(--icon-valid);
}
input:not([type=checkbox], [type=radio], [type=range], [type=file])[type=search][aria-invalid=true] {
  background-image: var(--icon-search), var(--icon-invalid);
}

[dir=rtl] :where(input):not([type=checkbox], [type=radio], [type=range], [type=file])[type=search] {
  background-position: center right 1.125rem;
}
[dir=rtl] :where(input):not([type=checkbox], [type=radio], [type=range], [type=file])[type=search][aria-invalid] {
  background-position: center right 1.125rem, center left 0.75rem;
}

details {
  display: block;
  margin-bottom: var(--spacing);
}
details summary {
  line-height: 1rem;
  list-style-type: none;
  cursor: pointer;
  transition: color var(--transition);
}
details summary:not([role]) {
  color: var(--accordion-close-summary-color);
}
details summary::-webkit-details-marker {
  display: none;
}
details summary::marker {
  display: none;
}
details summary::-moz-list-bullet {
  list-style-type: none;
}
details summary::after {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-inline-start: calc(var(--spacing, 1rem) * 0.5);
  float: right;
  transform: rotate(-90deg);
  background-image: var(--icon-chevron);
  background-position: right center;
  background-size: 1rem auto;
  background-repeat: no-repeat;
  content: "";
  transition: transform var(--transition);
}
details summary:focus {
  outline: none;
}
details summary:focus:not([role]) {
  color: var(--accordion-active-summary-color);
}
details summary:focus-visible:not([role]) {
  outline: var(--outline-width) solid var(--primary-focus);
  outline-offset: calc(var(--spacing, 1rem) * 0.5);
  color: var(--primary);
}
details summary[role=button] {
  width: 100%;
  text-align: left;
}
details summary[role=button]::after {
  height: calc(1rem * var(--line-height, 1.5));
}
details[open] > summary {
  margin-bottom: var(--spacing);
}
details[open] > summary:not([role]):not(:focus) {
  color: var(--accordion-open-summary-color);
}
details[open] > summary::after {
  transform: rotate(0);
}

[dir=rtl] details summary {
  text-align: right;
}
[dir=rtl] details summary::after {
  float: left;
  background-position: left center;
}

article {
  margin-bottom: var(--block-spacing-vertical);
  padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
  border-radius: var(--border-radius);
  background: var(--card-background-color);
  box-shadow: var(--card-box-shadow);
}
article > header,
article > footer {
  margin-right: calc(var(--block-spacing-horizontal) * -1);
  margin-left: calc(var(--block-spacing-horizontal) * -1);
  padding: calc(var(--block-spacing-vertical) * 0.66) var(--block-spacing-horizontal);
  background-color: var(--card-sectioning-background-color);
}
article > header {
  margin-top: calc(var(--block-spacing-vertical) * -1);
  margin-bottom: var(--block-spacing-vertical);
  border-bottom: var(--border-width) solid var(--card-border-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
article > footer {
  margin-top: var(--block-spacing-vertical);
  margin-bottom: calc(var(--block-spacing-vertical) * -1);
  border-top: var(--border-width) solid var(--card-border-color);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

[role=search],
[role=group] {
  display: inline-flex;
  position: relative;
  width: 100%;
  margin-bottom: var(--spacing);
  border-radius: var(--border-radius);
  box-shadow: var(--group-box-shadow, 0 0 0 rgba(0, 0, 0, 0));
  vertical-align: middle;
  transition: box-shadow var(--transition);
}
[role=search] > *,
[role=search] input:not([type=checkbox], [type=radio]),
[role=search] select,
[role=group] > *,
[role=group] input:not([type=checkbox], [type=radio]),
[role=group] select {
  position: relative;
  flex: 1 1 auto;
  margin-bottom: 0;
}
[role=search] > *:not(:first-child),
[role=search] input:not([type=checkbox], [type=radio]):not(:first-child),
[role=search] select:not(:first-child),
[role=group] > *:not(:first-child),
[role=group] input:not([type=checkbox], [type=radio]):not(:first-child),
[role=group] select:not(:first-child) {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
[role=search] > *:not(:last-child),
[role=search] input:not([type=checkbox], [type=radio]):not(:last-child),
[role=search] select:not(:last-child),
[role=group] > *:not(:last-child),
[role=group] input:not([type=checkbox], [type=radio]):not(:last-child),
[role=group] select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[role=search] > *:focus,
[role=search] input:not([type=checkbox], [type=radio]):focus,
[role=search] select:focus,
[role=group] > *:focus,
[role=group] input:not([type=checkbox], [type=radio]):focus,
[role=group] select:focus {
  z-index: 2;
}
[role=search] button:not(:first-child),
[role=search] [type=submit]:not(:first-child),
[role=search] [type=reset]:not(:first-child),
[role=search] [type=button]:not(:first-child),
[role=search] [role=button]:not(:first-child),
[role=search] input:not([type=checkbox], [type=radio]):not(:first-child),
[role=search] select:not(:first-child),
[role=group] button:not(:first-child),
[role=group] [type=submit]:not(:first-child),
[role=group] [type=reset]:not(:first-child),
[role=group] [type=button]:not(:first-child),
[role=group] [role=button]:not(:first-child),
[role=group] input:not([type=checkbox], [type=radio]):not(:first-child),
[role=group] select:not(:first-child) {
  margin-left: calc(var(--border-width) * -1);
}
[role=search] button,
[role=search] [type=submit],
[role=search] [type=reset],
[role=search] [type=button],
[role=search] [role=button],
[role=group] button,
[role=group] [type=submit],
[role=group] [type=reset],
[role=group] [type=button],
[role=group] [role=button] {
  width: auto;
}
@supports selector(:has(*)) {
  [role=search]:has(button:focus, [type=submit]:focus, [type=button]:focus, [role=button]:focus),
  [role=group]:has(button:focus, [type=submit]:focus, [type=button]:focus, [role=button]:focus) {
    --group-box-shadow: var(--group-box-shadow-focus-with-button);
  }
  [role=search]:has(button:focus, [type=submit]:focus, [type=button]:focus, [role=button]:focus) input:not([type=checkbox], [type=radio]),
  [role=search]:has(button:focus, [type=submit]:focus, [type=button]:focus, [role=button]:focus) select,
  [role=group]:has(button:focus, [type=submit]:focus, [type=button]:focus, [role=button]:focus) input:not([type=checkbox], [type=radio]),
  [role=group]:has(button:focus, [type=submit]:focus, [type=button]:focus, [role=button]:focus) select {
    border-color: transparent;
  }
  [role=search]:has(input:not([type=submit], [type=button]):focus, select:focus),
  [role=group]:has(input:not([type=submit], [type=button]):focus, select:focus) {
    --group-box-shadow: var(--group-box-shadow-focus-with-input);
  }
  [role=search]:has(input:not([type=submit], [type=button]):focus, select:focus) button,
  [role=search]:has(input:not([type=submit], [type=button]):focus, select:focus) [type=submit],
  [role=search]:has(input:not([type=submit], [type=button]):focus, select:focus) [type=button],
  [role=search]:has(input:not([type=submit], [type=button]):focus, select:focus) [role=button],
  [role=group]:has(input:not([type=submit], [type=button]):focus, select:focus) button,
  [role=group]:has(input:not([type=submit], [type=button]):focus, select:focus) [type=submit],
  [role=group]:has(input:not([type=submit], [type=button]):focus, select:focus) [type=button],
  [role=group]:has(input:not([type=submit], [type=button]):focus, select:focus) [role=button] {
    --button-box-shadow: 0 0 0 var(--border-width) var(--primary-border);
    --button-hover-box-shadow: 0 0 0 var(--border-width) var(--primary-hover-border);
  }
  [role=search] button:focus,
  [role=search] [type=submit]:focus,
  [role=search] [type=reset]:focus,
  [role=search] [type=button]:focus,
  [role=search] [role=button]:focus,
  [role=group] button:focus,
  [role=group] [type=submit]:focus,
  [role=group] [type=reset]:focus,
  [role=group] [type=button]:focus,
  [role=group] [role=button]:focus {
    box-shadow: none;
  }
}

[role=search] > *:first-child {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}
[role=search] > *:last-child {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

[aria-busy=true]:not(input, select, textarea, html, form) {
  white-space: nowrap;
}
[aria-busy=true]:not(input, select, textarea, html, form)::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-image: var(--icon-loading);
  background-size: 1em auto;
  background-repeat: no-repeat;
  content: "";
  vertical-align: -0.125em;
}
[aria-busy=true]:not(input, select, textarea, html, form):not(:empty)::before {
  margin-inline-end: calc(var(--spacing) * 0.5);
}
[aria-busy=true]:not(input, select, textarea, html, form):empty {
  text-align: center;
}

button[aria-busy=true],
[type=submit][aria-busy=true],
[type=button][aria-busy=true],
[type=reset][aria-busy=true],
[role=button][aria-busy=true],
a[aria-busy=true] {
  pointer-events: none;
}

:root,
:host {
  --scrollbar-width: 0px;
}

dialog {
  display: flex;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: inherit;
  min-width: 100%;
  height: inherit;
  min-height: 100%;
  padding: 0;
  border: 0;
  -webkit-backdrop-filter: var(--modal-overlay-backdrop-filter);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  background-color: var(--modal-overlay-background-color);
  color: var(--color);
}
dialog > article {
  width: 100%;
  max-height: calc(100vh - var(--spacing) * 2);
  margin: var(--spacing);
  overflow: auto;
}
@media (min-width: 576px) {
  dialog > article {
    max-width: 510px;
  }
}
@media (min-width: 768px) {
  dialog > article {
    max-width: 700px;
  }
}
dialog > article > header > * {
  margin-bottom: 0;
}
dialog > article > header :is(a, button)[rel=prev] {
  margin: 0;
  margin-left: var(--spacing);
  padding: 0;
  float: right;
}
dialog > article > footer {
  text-align: right;
}
dialog > article > footer button,
dialog > article > footer [role=button] {
  margin-bottom: 0;
}
dialog > article > footer button:not(:first-of-type),
dialog > article > footer [role=button]:not(:first-of-type) {
  margin-left: calc(var(--spacing) * 0.5);
}
dialog > article :is(a, button)[rel=prev] {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-top: calc(var(--spacing) * -1);
  margin-bottom: var(--spacing);
  margin-left: auto;
  border: none;
  background-image: var(--icon-close);
  background-position: center;
  background-size: auto 1rem;
  background-repeat: no-repeat;
  background-color: transparent;
  opacity: 0.5;
  transition: opacity var(--transition);
}
dialog > article :is(a, button)[rel=prev]:is([aria-current]:not([aria-current=false]), :hover, :active, :focus) {
  opacity: 1;
}
dialog:not([open]), dialog[open=false] {
  display: none;
}

:where(nav li)::before {
  float: left;
  content: "​";
}

nav,
nav ul {
  display: flex;
}

nav {
  justify-content: space-between;
  overflow: visible;
}
nav ol,
nav ul {
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
nav ol:first-of-type,
nav ul:first-of-type {
  margin-left: calc(var(--nav-element-spacing-horizontal) * -1);
}
nav ol:last-of-type,
nav ul:last-of-type {
  margin-right: calc(var(--nav-element-spacing-horizontal) * -1);
}
nav li {
  display: inline-block;
  margin: 0;
  padding: var(--nav-element-spacing-vertical) var(--nav-element-spacing-horizontal);
}
nav li :where(a, [role=link]) {
  display: inline-block;
  margin: calc(var(--nav-link-spacing-vertical) * -1) calc(var(--nav-link-spacing-horizontal) * -1);
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
  border-radius: var(--border-radius);
}
nav li :where(a, [role=link]):not(:hover) {
  text-decoration: none;
}
nav li button,
nav li [role=button],
nav li [type=button],
nav li input:not([type=checkbox], [type=radio], [type=range], [type=file]),
nav li select {
  height: auto;
  margin-right: inherit;
  margin-bottom: 0;
  margin-left: inherit;
  padding: calc(var(--nav-link-spacing-vertical) - var(--border-width) * 2) var(--nav-link-spacing-horizontal);
}
nav[aria-label=breadcrumb] {
  align-items: center;
  justify-content: start;
}
nav[aria-label=breadcrumb] ul li:not(:first-child) {
  margin-inline-start: var(--nav-link-spacing-horizontal);
}
nav[aria-label=breadcrumb] ul li a {
  margin: calc(var(--nav-link-spacing-vertical) * -1) 0;
  margin-inline-start: calc(var(--nav-link-spacing-horizontal) * -1);
}
nav[aria-label=breadcrumb] ul li:not(:last-child)::after {
  display: inline-block;
  position: absolute;
  width: calc(var(--nav-link-spacing-horizontal) * 4);
  margin: 0 calc(var(--nav-link-spacing-horizontal) * -1);
  content: var(--nav-breadcrumb-divider);
  color: var(--muted-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}
nav[aria-label=breadcrumb] a[aria-current]:not([aria-current=false]) {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  pointer-events: none;
}

aside nav,
aside ol,
aside ul,
aside li {
  display: block;
}
aside li {
  padding: calc(var(--nav-element-spacing-vertical) * 0.5) var(--nav-element-spacing-horizontal);
}
aside li a {
  display: block;
}
aside li [role=button] {
  margin: inherit;
}

[dir=rtl] nav[aria-label=breadcrumb] ul li:not(:last-child) ::after {
  content: "\\";
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  appearance: none;
  width: 100%;
  height: 0.5rem;
  margin-bottom: calc(var(--spacing) * 0.5);
  overflow: hidden;
  border: 0;
  border-radius: var(--border-radius);
  background-color: var(--progress-background-color);
  color: var(--progress-color);
}
progress::-webkit-progress-bar {
  border-radius: var(--border-radius);
  background: none;
}
progress[value]::-webkit-progress-value {
  background-color: var(--progress-color);
  -webkit-transition: inline-size var(--transition);
  transition: inline-size var(--transition);
}
progress::-moz-progress-bar {
  background-color: var(--progress-color);
}
@media (prefers-reduced-motion: no-preference) {
  progress:indeterminate {
    background: var(--progress-background-color) linear-gradient(to right, var(--progress-color) 30%, var(--progress-background-color) 30%) top left/150% 150% no-repeat;
    animation: progress-indeterminate 1s linear infinite;
  }
  progress:indeterminate[value]::-webkit-progress-value {
    background-color: transparent;
  }
  progress:indeterminate::-moz-progress-bar {
    background-color: transparent;
  }
}

@media (prefers-reduced-motion: no-preference) {
  [dir=rtl] progress:indeterminate {
    animation-direction: reverse;
  }
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
[data-tooltip] {
  position: relative;
}
[data-tooltip]:not(a, button, input, [role=button]) {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help;
}
[data-tooltip][data-placement=top]::before, [data-tooltip][data-placement=top]::after, [data-tooltip]::before, [data-tooltip]::after {
  display: block;
  z-index: 99;
  position: absolute;
  bottom: 100%;
  left: 50%;
  padding: 0.25rem 0.5rem;
  overflow: hidden;
  transform: translate(-50%, -0.25rem);
  border-radius: var(--border-radius);
  background: var(--tooltip-background-color);
  content: attr(data-tooltip);
  color: var(--tooltip-color);
  font-style: normal;
  font-weight: var(--font-weight);
  font-size: 0.875rem;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
}
[data-tooltip][data-placement=top]::after, [data-tooltip]::after {
  padding: 0;
  transform: translate(-50%, 0rem);
  border-top: 0.3rem solid;
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  border-radius: 0;
  background-color: transparent;
  content: "";
  color: var(--tooltip-background-color);
}
[data-tooltip][data-placement=bottom]::before, [data-tooltip][data-placement=bottom]::after {
  top: 100%;
  bottom: auto;
  transform: translate(-50%, 0.25rem);
}
[data-tooltip][data-placement=bottom]:after {
  transform: translate(-50%, -0.3rem);
  border: 0.3rem solid transparent;
  border-bottom: 0.3rem solid;
}
[data-tooltip][data-placement=left]::before, [data-tooltip][data-placement=left]::after {
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  transform: translate(-0.25rem, -50%);
}
[data-tooltip][data-placement=left]:after {
  transform: translate(0.3rem, -50%);
  border: 0.3rem solid transparent;
  border-left: 0.3rem solid;
}
[data-tooltip][data-placement=right]::before, [data-tooltip][data-placement=right]::after {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 100%;
  transform: translate(0.25rem, -50%);
}
[data-tooltip][data-placement=right]:after {
  transform: translate(-0.3rem, -50%);
  border: 0.3rem solid transparent;
  border-right: 0.3rem solid;
}
[data-tooltip]:focus::before, [data-tooltip]:focus::after, [data-tooltip]:hover::before, [data-tooltip]:hover::after {
  opacity: 1;
}
@media (hover: hover) and (pointer: fine) {
  [data-tooltip]:focus::before, [data-tooltip]:focus::after, [data-tooltip]:hover::before, [data-tooltip]:hover::after {
    --tooltip-slide-to: translate(-50%, -0.25rem);
    transform: translate(-50%, 0.75rem);
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-name: tooltip-slide;
    opacity: 0;
  }
  [data-tooltip]:focus::after, [data-tooltip]:hover::after {
    --tooltip-caret-slide-to: translate(-50%, 0rem);
    transform: translate(-50%, -0.25rem);
    animation-name: tooltip-caret-slide;
  }
  [data-tooltip][data-placement=bottom]:focus::before, [data-tooltip][data-placement=bottom]:focus::after, [data-tooltip][data-placement=bottom]:hover::before, [data-tooltip][data-placement=bottom]:hover::after {
    --tooltip-slide-to: translate(-50%, 0.25rem);
    transform: translate(-50%, -0.75rem);
    animation-name: tooltip-slide;
  }
  [data-tooltip][data-placement=bottom]:focus::after, [data-tooltip][data-placement=bottom]:hover::after {
    --tooltip-caret-slide-to: translate(-50%, -0.3rem);
    transform: translate(-50%, -0.5rem);
    animation-name: tooltip-caret-slide;
  }
  [data-tooltip][data-placement=left]:focus::before, [data-tooltip][data-placement=left]:focus::after, [data-tooltip][data-placement=left]:hover::before, [data-tooltip][data-placement=left]:hover::after {
    --tooltip-slide-to: translate(-0.25rem, -50%);
    transform: translate(0.75rem, -50%);
    animation-name: tooltip-slide;
  }
  [data-tooltip][data-placement=left]:focus::after, [data-tooltip][data-placement=left]:hover::after {
    --tooltip-caret-slide-to: translate(0.3rem, -50%);
    transform: translate(0.05rem, -50%);
    animation-name: tooltip-caret-slide;
  }
  [data-tooltip][data-placement=right]:focus::before, [data-tooltip][data-placement=right]:focus::after, [data-tooltip][data-placement=right]:hover::before, [data-tooltip][data-placement=right]:hover::after {
    --tooltip-slide-to: translate(0.25rem, -50%);
    transform: translate(-0.75rem, -50%);
    animation-name: tooltip-slide;
  }
  [data-tooltip][data-placement=right]:focus::after, [data-tooltip][data-placement=right]:hover::after {
    --tooltip-caret-slide-to: translate(-0.3rem, -50%);
    transform: translate(-0.05rem, -50%);
    animation-name: tooltip-caret-slide;
  }
}
@keyframes tooltip-slide {
  to {
    transform: var(--tooltip-slide-to);
    opacity: 1;
  }
}
@keyframes tooltip-caret-slide {
  50% {
    opacity: 0;
  }
  to {
    transform: var(--tooltip-caret-slide-to);
    opacity: 1;
  }
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
}

[dir=rtl] {
  direction: rtl;
}

@media (prefers-reduced-motion: reduce) {
  *:not([aria-busy=true]),
  :not([aria-busy=true])::before,
  :not([aria-busy=true])::after {
    background-attachment: initial !important;
    animation-duration: 1ms !important;
    animation-delay: -1ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }
}